<template>
  <div>
    <el-form class="security-div">
      <el-input class="security-input" v-model="security" :placeholder="this.$t('Please.security_code')"  clearable/>
      <!--搜索-->
      <el-button :loading="btnLoading" icon="el-icon-search" plain type="primary" @click="handleFilter(security)">
        {{ $t('operation.button.search') }}
      </el-button>
    </el-form>
  </div>
</template>

<script>
  import axios from 'axios'
  export default {
    name: "SecurityCode",
    data() {
      return {
        security: '',
        btnLoading: false,
      }
    },
    methods: {
      handleFilter(v) {
        this.btnLoading = true
        const a = v.slice(0, 4)
        if (a === '9012') {
          this.btnLoading = false
          axios({
            url: "http://47.107.167.7:8085/cabby_code_v2/open/fwQueryRecord/query?fw_code=" + v,
            method: 'GET',
          }).then(res => {
            if (res.data.code === '200' && res.data.success === true) {
              this.$message.success(this.$t('Security_Code.success'))
            } else if (res.data.code === '200' && res.data.success === false) {
              this.$message.error(this.$t('Security_Code.error'))
            }
          }).catch(err => {
            console.error(err)
          })
        } else {
          this.btnLoading = false
          return this.$message.error(this.$t('Please.success.security_code'))
        }
      }
    }
  }
</script>

<style scoped lang="scss">
.security-div {
  padding: 2rem;
  text-align: center;
}
.security-input {
  width: 65%;
}
@media screen and (max-width: 991px) {
  .security-div {
    text-align: left;
  }
  .security-input {
    width: 78%;
    margin-bottom: 0.3rem;
  }
}

</style>
